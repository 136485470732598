import React from "react";
import TestimonialBox from "./TestimonialBox";

function TestimonialBoxes() {
  return (
    <>
      {/* <TestimonialBox
        text=" “I've been a member of Gymate for the past 6 months and it has been an amazing experience. The trainers are knowledgeable and supportive, the
                  equipment is top-notch, and the community of members is friendly and
                  encouraging.”"
        name="John Wick"
        job="Starbucks Employee"
      /> */}
      <TestimonialBox
        text=" “I've been a member of Gymate for the past 6 months and it has been an amazing experience. The trainers are knowledgeable and supportive, the
                  equipment is top-notch, and the community of members is friendly and
                  encouraging.”"
        name="Harry Potter"
        job="CEO of Hogwarts"
      />
      {/* <TestimonialBox
        text=" “I've been a member of Gymate for the past 6 months and it has been an amazing experience. The trainers are knowledgeable and supportive, the
                  equipment is top-notch, and the community of members is friendly and
                  encouraging.”"
        name="Bill Gates"
        job="Founder of Microsoft"
      /> */}
    </>
  );
}

export default TestimonialBoxes;
